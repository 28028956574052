import { Check } from '@backstage-community/plugin-tech-insights-common';
import { ScoutFact } from './facts-schema';
import { CheckResult } from '@backstage-community/plugin-tech-insights-common';
import { z } from 'zod';

export const CHECK_TYPE = 'scout-check';

export const FACT_RETRIEVER_ENGINEERING_METRICS =
  'engineeringMetricCollectorFactRetriever';

export enum CheckState {
  Pass = 'pass',
  Fail = 'fail',
  Warn = 'warn',
  NotApplicable = 'not-applicable',
  DataMissing = 'data-missing',
  ProcessingError = 'processing-error',
}

export enum Criticality {
  Platinum = 'platinum',
  Gold = 'gold',
  Silver = 'silver',
  Bronze = 'bronze',
  NotInUse = 'notinuse',
  Undefined = 'undefined',
}

const CheckStateSchema = z.nativeEnum(CheckState);

export const MultiStateSchema = z.array(
  z.object({
    info: z.string(),
    state: CheckStateSchema,
  }),
);

export type MultiState = z.infer<typeof MultiStateSchema>;

const ScoutCheckResultSchema = z.object({
  state: CheckStateSchema,
  pointsAwarded: z.number().int(),
  subStates: MultiStateSchema.optional(),
  error: z.string().optional(),
});

const EntityScoutCheckResultsSchema = z.record(
  z.string(),
  ScoutCheckResultSchema,
);

// A map of entityRef -> checkId -> scoutCheckResult that can be used for storing
// bulk check results.
// Only contains check state information
// Used by CSV export
const EntitiesScoutCheckResultsSchema = z.record(
  z.string(),
  EntityScoutCheckResultsSchema,
);

export const DatesEntitiesCheckResultsSchema = z.record(
  z.string(),
  EntitiesScoutCheckResultsSchema,
);

export type ScoutCheckResult = z.infer<typeof ScoutCheckResultSchema>;

type EntityCheckResults = {
  [checkId: string]: CheckResult;
};

// A map of entityRef -> checkId -> checkResult that can be used for storing
// bulk check results.
// Used by useCheckResults hook
export type EntitiesCheckResults = {
  [entityRef: string]: EntityCheckResults;
};

// A map of dates -> entityRef -> checkId -> checkResult. Dates are in YYYY-MM-DD
// string format.
export type DatesEntitiesCheckResults = z.infer<
  typeof DatesEntitiesCheckResultsSchema
>;

const checkIds = [
  'framework-end-of-life',
  'runtime-end-of-life',
  'dependabot-high-severity-alerts',
  'semgrep-high-severity',
  'prohibited-dependencies',
  'dependabot-enabled',
  'metadata-is-valid',
  'template-is-used',
  'c4-container-exists',
  'stale-branches',
] as const;

// make typescript validate that string literals used for check ids are valid where we use them
export type CheckId = (typeof checkIds)[number];

export type CheckGroup = {
  id: CheckCategory;
  name: string;
  description: string;
  checkIds: CheckId[];
};

export enum CheckCategory {
  SECURITY = 'security',
  COMPLIANCE = 'compliance',
  STANDARDS = 'standards',
}

export interface ScoutCheck extends Check {
  id: CheckId;
  documentation: string;
  shortName: string;
  maxPoints: number;

  run(fact: ScoutFact): ScoutCheckResult;
}

export type SimpleScoutCheck = Omit<ScoutCheck, 'type' | 'factIds'>;

export const EarliestResponseSchema = z.object({
  date: z.coerce.date().nullable(),
});
// Type used for JSON response in the /earliest endpoint
export type EarliestResponse = z.infer<typeof EarliestResponseSchema>;

// Type used for query params in the /history endpoint
export type HistoryQuery = {
  from: string;
  to: string;
};

export const AggregatedScoreSchema = z.object({
  componentId: z.string(),
  runDate: z.coerce.date(),
  totalPointsAwarded: z.number().int(),
  totalMaxPoints: z.number().int(),
});

export type AggregatedScore = z.infer<typeof AggregatedScoreSchema>;

export const BreakdownSchema = z.record(
  z.object({
    pointsAwarded: z.number().int(),
    maxPoints: z.number().int(),
  }),
);

export type Breakdown = z.infer<typeof BreakdownSchema>;

export const TotalAggregatedScoreSchema = z.array(
  z.object({
    runDate: z.coerce.date(),
    totalPointsAwarded: z.number().int(),
    totalMaxPoints: z.number().int(),
    breakdown: BreakdownSchema,
  }),
);

export type TotalAggregatedScore = z.infer<typeof TotalAggregatedScoreSchema>;

export const AggregatedScoreQuerySchema = z.object({
  // the only reason it's team and not teams is that this is a query param, and to construct an array you have to repeat the same parameter ?team=a&team=b&team=c
  team: z
    .union([z.string(), z.array(z.string())])
    .transform(val => (typeof val === 'string' ? [val] : val))
    .optional(),
  from: z.coerce.date(),
  to: z.coerce.date(),
});

export type AggregatedScoreQuery = z.infer<typeof AggregatedScoreQuerySchema>;

import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import { TechHealthApi } from './TechHealthApi';
import { AuthService } from '@backstage/backend-plugin-api';
import {
  AggregatedScoreQuery,
  DatesEntitiesCheckResults,
  DatesEntitiesCheckResultsSchema,
  EarliestResponseSchema,
  TotalAggregatedScore,
  TotalAggregatedScoreSchema,
} from '../types';

type TechHealthClientOptions = {
  discoveryApi: DiscoveryApi;
  identityApi: IdentityApi | AuthService;
};

export class TechHealthClient implements TechHealthApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi | AuthService;

  constructor(options: TechHealthClientOptions) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  async api(
    path: string,
    params?: URLSearchParams,
    pluginId: string = 'tech-insights',
  ): Promise<Response> {
    const baseUrl = await this.discoveryApi.getBaseUrl(pluginId);
    const url = new URL(`${baseUrl}/${path}`);
    if (params !== undefined) {
      url.search = params.toString();
    }
    const request = new Request(url);

    const token = await this.getToken();
    if (token) {
      request.headers.set('Authorization', `Bearer ${token}`);
    }

    const response = await fetch(request);
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return response;
  }

  async getAggregatedScore(
    params: AggregatedScoreQuery,
  ): Promise<TotalAggregatedScore> {
    const { from, to, team: teams } = params;
    const queryParamTuples = [
      ['from', from.toISOString()],
      ['to', to.toISOString()],
    ];
    if (teams !== undefined) {
      queryParamTuples.push(...teams.map(team => ['team', team]));
    }
    const response = await this.api(
      'aggregate',
      new URLSearchParams(queryParamTuples),
      'tech-health-scout',
    );

    return TotalAggregatedScoreSchema.parse(await response.json());
  }

  async getCSVExport(from: Date, to: Date): Promise<Blob> {
    const response = await this.api(
      'csv-export',
      new URLSearchParams({ from: from.toISOString(), to: to.toISOString() }),
    );

    return response.blob();
  }

  async getEarliestDate(): Promise<Date | null> {
    const response = await this.api('earliest');
    const body = EarliestResponseSchema.parse(await response.json());
    return body.date;
  }

  // both `from` and `to` are inclusive
  async getHistory(from: Date, to: Date): Promise<DatesEntitiesCheckResults> {
    const response = await this.api(
      'history',
      new URLSearchParams({
        from: from.toISOString(),
        to: to.toISOString(),
      }),
    );

    return DatesEntitiesCheckResultsSchema.parse(await response.json());
  }

  private async getToken(): Promise<string | null> {
    let result: { token?: string | undefined };

    if ('getCredentials' in this.identityApi) {
      result = await this.identityApi.getCredentials();
    } else {
      result = await this.identityApi.getPluginRequestToken({
        onBehalfOf: await this.identityApi.getOwnServiceCredentials(),
        targetPluginId: 'tech-insights',
      });
    }

    return result.token ?? null;
  }
}

import React from 'react';
import { Button } from '@material-ui/core';
import { useEntityList } from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  button: {
    padding: theme.spacing(1, 2),
    margin: theme.spacing(0, 1),
    lineHeight: 1.43,
  },
}));

// Define the headers and their corresponding paths in the entity
const EXPORT_CONFIG = [
  { header: 'Account Name', path: 'metadata.title' },
  { header: 'Account ID', path: 'metadata.name' },
  { header: 'Cost Center', path: 'spec.costCentre' },
] as const;

// Helper function to get nested object value by path
const getNestedValue = (obj: any, path: string): string => {
  try {
    const keys = path.split('.');
    let current = obj;

    for (const key of keys) {
      if (!(key in current)) {
        throw new Error(`Required field "${key}" in path "${path}" is missing`);
      }
      current = current[key];
    }

    return String(current);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return `Something Went Wrong: ${error}`;
  }
};

export function CustomExportButton() {
  const { entities } = useEntityList();
  const classes = useStyles();

  const handleExport = () => {
    // Convert entities to CSV
    const headers = EXPORT_CONFIG.map(config => config.header);
    const csvRows = [
      headers.join(','),
      ...entities.map((entity: Entity) =>
        EXPORT_CONFIG.map(config =>
          JSON.stringify(getNestedValue(entity, config.path) || ''),
        ).join(','),
      ),
    ];
    const csvContent = csvRows.join('\n');

    // Create and trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const downloadUrl = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = `uSOFA-${new Date().toISOString().split('T')[0]}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadUrl);
  };

  return (
    <Button
      className={classes.button}
      variant="contained"
      color="primary"
      onClick={handleExport}
    >
      Export Data
    </Button>
  );
}

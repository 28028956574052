import {
  Content,
  ContentHeader,
  LinkButton,
  PageWithHeader,
  SupportButton,
} from '@backstage/core-components';
import {
  CatalogFilterLayout,
  EntityListProvider,
  EntityTypePicker,
  UserListPicker,
} from '@backstage/plugin-catalog-react';
import React from 'react';
import { CustomCatalogTable } from '../../CustomCatalogTable';
import { DefaultCatalogPageProps } from '../../CustomCatalogPage';
import { AuthorityEntityPicker } from '../filters/AuthorityEntityFilter/AuthorityEntityPicker';
import { OrphanedAwsEntityPicker } from '../filters/OrphanedAccountsFilter/OrphanedAwsEntityPicker';
import { CustomExportButton } from './CustomExportButton';

export function AwsCatalogPage(props: DefaultCatalogPageProps) {
  const {
    columns,
    actions,
    initiallySelectedFilter = 'all',
    tableOptions = {},
  } = props;

  return (
    <PageWithHeader
      title="AWS Accounts"
      subtitle="Find any metadata related to an AWS account"
      themeId="service"
    >
      <EntityListProvider>
        <Content>
          <ContentHeader title="AWS Accounts list">
            <LinkButton
              to="/aws-account-creation"
              color="primary"
              variant="contained"
              underline="none"
            >
              Request new AWS account
            </LinkButton>
            <CustomExportButton />
            <SupportButton>
              Got any feedback for new functionality? Contact us
            </SupportButton>
          </ContentHeader>
          <CatalogFilterLayout>
            <CatalogFilterLayout.Filters>
              <EntityTypePicker initialFilter="aws-account" hidden />
              <UserListPicker
                initialFilter={initiallySelectedFilter}
                availableFilters={['starred', 'all']}
              />
              <AuthorityEntityPicker />
              <OrphanedAwsEntityPicker />
            </CatalogFilterLayout.Filters>
            <CatalogFilterLayout.Content>
              <CustomCatalogTable
                columns={columns}
                actions={actions}
                tableOptions={tableOptions}
              />
            </CatalogFilterLayout.Content>
          </CatalogFilterLayout>
        </Content>
      </EntityListProvider>
    </PageWithHeader>
  );
}
